import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareGroupInsurance = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Care Group Insurance | Employer Plans | LTCPG"
      ogDescription="Long term care group insurance can make your employee packages more attractive. The Long Term Care Planning Group provides education and on-boarding serices for your team."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            Long Term Care Group Insurance
          </h1>
          <p>
            One of the things that distinguishes The Long Term Care Planning Group is our <a href="/long-term-care-insurance-specialist/">exclusive focus</a> on <a href="/long-term-care-planning/">long term care planning</a> since 2001. Corey Rieck and his team are considered experts in long term can planning. Clients appreciate working with us to provide their long term care group insurance for several reasons.
          </p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_84436703.jpg"
            alt="Long Term Care Group Insurance"
          />
        </div>
      }
    />
    <QuotePanel text="Hello World" person="John Doe" />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img
            src="/images/corey-rieck-ltc-specialist.jpg"
            alt="Long Term Care Group Insurance - Reasons"
          />
        </div>
      }
      textRight={
        <div>
          <h2>
            Reasons to Work with Us on Your Long Term Care Group Insurance
          </h2>
          <ol>
            <li>Expertise gained from being <a href="/long-term-care-insurance-specialist/">exclusively focused on Long Term Care Planning</a> since 2001.</li>
            <li>Carrier agnostic to offer optimized insurance solutions and instrument options.</li>
            <li>Long standing relationships with <a href="/long-term-care-insurance-companies/">major insurance carriers</a> who broker their product at high levels.</li>
            <li>Experienced educational advisor able to adapt discussion level based on client’s focus and needs.</li>
            <li>Firsthand experiences with <a href="/impact-of-caregiving/">long term care needs for family members</a> and knows how the family becomes the “plan” if there is no Long Term Care Planning.</li>
            <li>Understands stressful shifts in family dynamics when coordinating care with siblings.</li>
            <li>Understands how <a href="/cost-of-long-term-care/">geography adversely affects care giving</a> and family relations.</li>
            <li>Consultative approach includes an extensive pre underwriting process for individual clients to know as quickly as possible what next steps are available to them.</li>
            <li>Ability to secure working conditions others may not know to get or ask about.</li>
            <li>Master Trainer for over 4,000 producers/advisors/agents from San Francisco to Wall Street since 2003 through the CLTC® (Certification in Long Term Care) program.</li>
          </ol>
          <ScheduleVisitButton />
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_137773804.jpg"
          alt="Long Term Care Group Insurance - Deliverables"
          className="left-point"
        />
      }
      imageRight
      imageGrow
      textPadded
      text={
        <div>
          <h2>Deliverables from The Long Term Care Planning Group</h2>
          <ul>
            <li>Execution of all education, pre-underwriting and formal carrier underwriting, plan design and plan funding.</li>
            <li><a href="https://www.census.gov/" target="_blank">Census data collection</a> and interaction with relevant LTC employer marketplace to determine carrier interest and then communicate options back to the employer.</li>
            <li>Negotiate underwriting concessions with the carrier where possible.</li>
            <li>Licensed in most states and can assist family members with questions regardless of location.</li>
          </ul>
          <RegisterForWebinarButton />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img
            src="/images/AdobeStock_62345450.jpg"
            alt="Long Term Care Group Insurance - Benefits"
          />
          <Blockquote>Long term care group insurance benefits can enhance an executive compensation package for employees who meet the “time, tenure and title” qualifications. This gives the employers competitive advantage in recruiting and retention of its key executives.</Blockquote>
        </div>
      }
      textRight={
        <div>
          <h2>
            Long Term Care Group Insurance Benefit Introduction and Enrollment Support Plan
          </h2>
          <ul>
            <li>Long term care educational awareness campaign via existing company resources to inform the employees of benefits (email, webinars, payroll stuffers, YouTube videos, mailings to home for spouses, etc.).</li>
            <li>Large group meetings at each location to offer education presentations.</li>
            <li>Individual “Forced Choice” long term care meetings.</li>
            <li>Make the long term care benefit enrollment a standalone process.</li>
            <li>The employer can class out the long term care offering.</li>
            <li>Employer premium payment options
              <ul>
                <li>Pay 100% of the employee premium</li>
                <li>Pay some of the employee premium</li>
                <li>Pay none of the employee premium</li>
              </ul>
            </li>
          </ul>
          <ScheduleVisitButton />
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_306848457.jpg"
          alt="Long Term Care Group Insurance - Incentives"
          className="left-point"
        />
      }
      imageRight
      imageGrow
      textPadded
      text={
        <div>
          <h2>Long Term Care Group Insurance Tax Incentives</h2>
          <h3>C-Corps</h3>
          <p>C-Corps can legally take a 100% tax deductible long term care group insurance premiums as a business expense. Long term care group insurance can be purchased for both owners of the corporation and its employees based on the “time, tenure and title” approach.</p>
          <ul>
            <li>Employer paid premiums are not added to the employee’s gross income</li>
            <li>Premiums paid are exempt from payroll taxes</li>
            <li>Long term care policy coverage can be made available to spouses/domestic partners and retirees</li>
          </ul>
        
          <h3>S-Corps Partners or More Than 2% Shareholders</h3>
          <ul>
            <li>Premiums paid for an owner are included as individual gross income.</li>
            <li>A self-employed health insurance deduction can be taken for tax-qualified long term care group insurance premiums paid. Long term care group insurance premiums are considered a medical expense. This is subject to the standard IRS limits based on age.</li>
          </ul>
          <RegisterForWebinarButton />
        </div>
      }
    />
    <ParallaxSection src={`/images/parallax/AdobeStock_245080120.jpg`} height={300} />
    <PageWrapper maxWidth color="green">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/transamerica-logo.jpg"
              alt="Long Term Care Group Insurance - Transamerica"
            />
          </div>
        }
        textRight={
          <div>
            <h2>Who are the Preferred Long Term Care Group Insurance Companies?</h2>

            <h3>Transamerica Life Insurance Company (TransAmerica)</h3>
            <h4>A.M. Best Rating A+</h4>

            <p>Plan Features include:</p>
            <ul>
              <li>Built in Benefits for those under 67:
                <ul>
                  <li>Additional Accident Benefit</li>
                  <li>Return of Premiums minus claims (if death before 67)</li>
                </ul>
              </li>
              <li>3 year rate guarantee built-in</li>
              <li>Tailored BIO Inflation automatically steps down the inflation protection amount at different stages of life to still meet partnership requirements</li>
              <li>Built-in Alternate Cash Benefit of 1/3 the monthly benefit</li>
              <li>Highest Partners Discounts of all carriers, currently 30% both 15% married on applying</li>
              <li>Built in Return of Premium at age 67</li>
              <li>Build in 0 day HHC Elimination Period Rider</li>
              <li>Built in Cash Benefit, up to 10 times the daily benefit</li>
              <li>Built in double accident benefit up to age 67</li>
              <li>Worksite/multi-life options:
                <ul>
                  <li>Unisex Pricing</li>
                  <li>Possible underwriting abbreviation/simplification depending on group</li>
                  <li>make up</li>
                  <li>5 year rate guarantee</li>
                </ul>
              </li>
            </ul>
            <ScheduleVisitButton />
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper maxWidth color="blue">
      <TextBlock
        textPadded
        textLeft={
      <div>
        <h3>Chubb</h3>
        <h4>A.M. Best Rating A+</h4>

        <p>Plan Features include:</p>
        <ul>
          <li>Premium Guaranteed through age 120</li>
          <li>Initial Death Benefit Guaranteed to age 70 or 25 years – whichever is longer</li>
          <li>At current Cost of Insurance and Interest Assumptions (3.5%), the FULL death benefit will not decrease and will be fully paid up prior to age 100</li>
          <li>Reduced Paid-up Term Insurance & Long Term Care Benefit begins in year 10.</li>
          <li>Optional Accelerated Benefit for Long Term Care provides a benefit of a full 4% per month (up to 100% of the face amount) for Nursing Home or In-home Care! Premiums are waived at no additional cost while receiving these benefits</li>
          <li>Plan is Fully Portable with no increase in premium</li>
          <li>Spouse and Dependent Child coverage available</li>
        </ul>

        <p>Give us a call to set up an appointment and talk about your long term care group insurance options <a href="tel:6788145088">(678) 814-5088</a>. This is a complimentary, no-obligation conversation.</p>
        <ScheduleVisitButton />
      </div>
        }
        textRight={
          <div>
            <img
              src="/images/chubb-logo.jpg"
              alt="Long Term Care Group Insurance - Chubb"
            />
          </div>
        }
      />
    </PageWrapper>
  </PageLayout>
  )
}

export default LongTermCareGroupInsurance
